import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authCheck, mintChannelOpen } from './actions';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Loader from '../loader/Loader';
import LoginPage from '../login/LoginPage';
import MainPage from '../main/MainPage';
import Meet from '../meet/Meet';
import Room from '../room/Room';
import '../css/App.scss';
import { isDev, locationHost } from './reducer';

function App() {
    const dispatch = useDispatch();
    const { _user, _wsUrl, _tsUpdateInterval, _clientId } = useSelector(
        (state) => {
            return {
                _user: state.app.user,
                _wsUrl: state.app.wsUrl,
                _tsUpdateInterval: state.app.tsUpdateInterval,
                _clientId: state.app.settings.clientId
            };
        }
    );

    useEffect(() => {
        let linkMintChannelLib = isDev
            ? `https://${locationHost}/libs/mint-client-lib.min.js?v=${Date.now()}`
            : '/libs/mint-client-lib.min.js';
        let script = document.createElement('script');
        script.src = linkMintChannelLib;
        script.type = 'text/javascript';
        document.body.appendChild(script);
        script.onload = function () {
            dispatch(mintChannelOpen(_wsUrl, _tsUpdateInterval));
            // dispatch(backdropToggled(false));
        };
        script.onerror = function () {
            console.warn('Ошибка при загрузке mint-channel-lib');
            // dispatch(backdropToggled(true))
        };

        // if (window.top.Office) {
        //     console.log('FOOBAR From office app');
        // } else {
        //     console.log('FOOBAR Not office app');
        // }

        // if (window.Office) {
        // const foobar = document.createElement('script');
        // foobar.src =
        //     'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js';
        // foobar.type = 'text/javascript';
        // document.head.appendChild(foobar);
        // foobar.onload = function () {
        //     console.info('Office.js loaded');
        // };
        // foobar.onerror = function () {
        //     console.warn('Office.js not loaded');
        // };
        // } else {
        //     console.log('FOOBAR Not office app');
        // }
    }, []);

    useEffect(() => {
        if (_clientId) {
            dispatch(authCheck());
        }
    }, [_clientId]);
    return (
        <>
            <Router>
                <Switch>
                    <Route
                        path={'/meet/:meetId'}
                        exact
                        render={(match) => <Meet {...match} />}
                    />
                    <Route path={'/room'} exact render={() => <Room />} />
                    <Route
                        path={'/room/:roomId'}
                        exact
                        render={(match) => <Room {...match} />}
                    />
                    <Route
                        path={'/room/:roomId/:hash'}
                        exact
                        render={(match) => <Room {...match} />}
                    />
                    <Route
                        path={'/webinar/:meetId'}
                        exact
                        render={(match) => <Meet {...match} webinar={true} />}
                    />
                    <Route
                        path={'/main'}
                        exact
                        render={() =>
                            !_user.isAuthChecked ? (
                                <Redirect to={'/'} />
                            ) : (
                                <MainPage />
                            )
                        }
                    />
                    <Route
                        path={'/login'}
                        exact
                        render={() =>
                            !_user.isAuthChecked ? (
                                <Redirect to={'/'} />
                            ) : (
                                <LoginPage />
                            )
                        }
                    />
                    <Route
                        path="/"
                        render={() =>
                            !_user.isAuthChecked ? (
                                <Loader preloader={true} />
                            ) : _user.name ? (
                                <Redirect to={'/main'} />
                            ) : (
                                <Redirect to={'/login'} />
                            )
                        }
                    />
                </Switch>
            </Router>

            <Loader />
        </>
    );
}

export default App;
